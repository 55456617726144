<template>
  <v-row
    v-if="isLoaded"
    class="sign-identity__wrapper mb-3 py-6 px-3"
    align="start"
    justify="start"
    no-gutters
    :style="`background: linear-gradient(135deg, ${partyColor} 24px, #ffffff 0);`"
  >
    <v-col class="col-12 col-md-auto h-100 align-self-center pa-3">
      <v-icon v-if="hasSequentialSigningOrder" color="#95AFDA" small>mdi-dots-grid</v-icon>
      <span class="sign-identity__order-number px-1">{{ signIdentityIndex + 1 }}</span>
    </v-col>

    <v-form ref="signIdentityForm" v-model="signIdentityFormValid" class="col-12 col-md px-3" lazy-validation>
      <v-row align="start" justify="start" no-gutters>
        <v-col class="col-12 col-md pr-6">
          <span class="sign-identity__settings-label">
            {{ $t('general.name_and_surname') }}
          </span>

          <v-text-field
            v-if="isProposerRole"
            :value="signIdentity.variable_position"
            class="sign-identity__settings-input"
            readonly
            dense
          ></v-text-field>

          <SignIdentitySearchInput
            v-else-if="canUseSearchInput"
            v-model.trim="fullName"
            :is-proposer-party="isProposer"
            :rules="fullNameRules"
            @on-create-sign-identity="toggleBlock('signIdentitySettings')"
            @on-update-sign-identity="updateSignIdentity(Object.assign({}, $event))"
          />

          <SignIdentitySelect
            v-else-if="isProposer"
            :sign-identity="signIdentity"
            :items-list="contractStore.availableProposers"
            @on-select-sign-identity="updateSignIdentity(Object.assign({}, $event, { is_proposer: true }))"
          />

          <v-text-field
            v-else
            v-model.trim="fullName"
            class="sign-identity__settings-input"
            :readonly="isProposer"
            :rules="fullNameRules"
            dense
          ></v-text-field>
        </v-col>

        <v-col class="col-12 col-md pr-6">
          <span
            class="sign-identity__settings-label"
            :class="{ 'sign-identity__settings-label--disabled': isProposerRole }"
          >
            {{ $t('general.email_simple') }}
          </span>

          <v-text-field
            v-if="isProposerRole"
            value="/"
            class="sign-identity__settings-input"
            :class="{ 'sign-identity__settings-input--disabled': isProposerRole }"
            :readonly="isProposerRole"
            dense
          ></v-text-field>

          <v-text-field
            v-else
            v-model.trim="email"
            class="sign-identity__settings-input"
            :rules="emailRules"
            :readonly="isProposer"
            dense
          ></v-text-field>
        </v-col>

        <v-col class="col-12 col-md pr-6">
          <span
            class="sign-identity__settings-label"
            :class="{ 'sign-identity__settings-label--disabled': isProposerRole }"
          >
            {{ $t('general.phone') }}
          </span>

          <v-text-field
            v-if="isProposerRole"
            value="/"
            class="sign-identity__settings-input"
            :class="{ 'sign-identity__settings-input--disabled': isProposerRole }"
            :readonly="isProposerRole"
            dense
          ></v-text-field>

          <v-text-field
            v-else
            v-model="phoneNumber"
            class="sign-identity__settings-input"
            :rules="phoneNumberRules"
            :readonly="isProposer"
            dense
          ></v-text-field>
        </v-col>

        <v-col class="col-12 col-md pr-6">
          <span class="sign-identity__settings-label">
            {{ $t('contract.select_role') }}
          </span>

          <v-select
            v-model="contractRole"
            class="sign-identity__settings-input"
            :items="availableSignTypes"
            item-value="value"
            :item-text="(item) => $t(item.translationKey)"
            :rules="contractRoleRules"
            hide-selected
            dense
          ></v-select>
        </v-col>

        <v-col class="col-12 col-md">
          <v-row align="center" justify="start" no-gutters>
            <v-col class="col-12 pb-2">
              <span class="sign-identity__settings-label">
                {{ $t('general.actions') }}
              </span>
            </v-col>

            <v-col class="col-12">
              <v-row align="start" justify="start" no-gutters>
                <v-col class="col-auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="hasVerification ? 'success' : blocks.verificationSettings ? 'primary' : '#95AFDA'"
                        :disabled="isDisabledVerification"
                        small
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="toggleBlock('verificationSettings')"
                      >
                        <v-icon small>mdi-shield-search</v-icon>
                      </v-btn>
                    </template>

                    <span>{{ verificationDescription }}</span>
                  </v-tooltip>
                </v-col>

                <v-col class="col-auto">
                  <v-menu v-if="!isDisabledChoosingWorkspace && accountWorkspaces.length" offset-y>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            color="#95AFDA"
                            small
                            icon
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            <v-icon small>mdi-table-account</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('contract.assign_workspace') }}</span>
                      </v-tooltip>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="item in accountWorkspaces"
                        :key="item.id"
                        @click="saveWorkspaceToSignIdentity(item)"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>

                <v-col class="col-auto">
                  <v-btn
                    color="#95AFDA"
                    :disabled="canAddPersonalizedNotification"
                    small
                    icon
                    @click="toggleBlock('notificationSettings')"
                  >
                    <v-icon small>mdi-email</v-icon>
                  </v-btn>
                </v-col>

                <v-col class="col-auto">
                  <v-btn
                    :color="blocks.signIdentitySettings ? 'primary' : '#95AFDA'"
                    :disabled="isProposer"
                    small
                    icon
                    @click="toggleBlock('signIdentitySettings')"
                  >
                    <v-icon small>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </v-col>

                <v-col class="col-auto">
                  <v-btn color="#95AFDA" small icon @click="deleteCurrentSignIdentity">
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="col-12 order-last order-md-0">
          <v-row align="center" justify="start">
            <v-col class="col-auto">
              <v-menu :disabled="isDisabledChangeRole" width="100%" max-width="272px" offset-y>
                <template v-slot:activator="{ on, attrs, value }">
                  <v-row
                    class="sign-identity__party-activator"
                    align="center"
                    justify="start"
                    no-gutters
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-col class="col-auto">
                      <v-icon color="#35D883" small left>mdi-account</v-icon>
                    </v-col>

                    <v-col class="col-auto">
                      <span class="sign-identity__party-activator--text">
                        {{ partyTitle }}
                      </span>
                    </v-col>

                    <v-col v-if="!isDisabledChangeRole" class="col-auto">
                      <v-icon color="#7136B3" small right>
                        {{ `mdi-chevron-${value ? 'up' : 'down'}` }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>

                <v-list class="sign-identity__party-list" dense>
                  <v-list-item
                    v-for="partyGroup in availablePartiesGroups"
                    :key="partyGroup"
                    @click="setSignIdentityPartyOrder(partyGroup)"
                  >
                    <v-list-item-title class="pl-1">
                      <span class="sign-identity__party-list--text">
                        {{
                          partyGroup === 'proposer'
                            ? $t('contract.proposer')
                            : `${$t('contract.counterparty')} ${partyGroup}`
                        }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>

                  <template v-if="canAddNewSignIdentityParty">
                    <v-divider></v-divider>

                    <v-list-item @click="setNewPartyOrder">
                      <v-list-item-title class="d-flex flex-row align-center">
                        <v-icon color="#95AFDA" clas="pl-0 pr-2">mdi-plus</v-icon>

                        <span class="party__parties-list__content--text">
                          {{ $t('contract.create.add_counterparty') }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </v-col>

            <v-col v-if="canSelectProposerRole && enableVariableUsers" class="col-auto">
              <v-menu width="100%" max-width="272px" offset-y>
                <template v-slot:activator="{ on, attrs, value }">
                  <v-row
                    class="sign-identity__party-activator"
                    align="center"
                    justify="start"
                    no-gutters
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-col class="col-auto">
                      <v-icon color="#95AFDA" small left>mdi-account-group</v-icon>
                    </v-col>

                    <v-col class="col-auto">
                      <span class="sign-identity__party-activator--text">
                        {{ $t('sign_identity.select_proposer_group') }}
                      </span>
                    </v-col>

                    <v-col class="col-auto">
                      <v-icon color="#7136B3" small right>
                        {{ `mdi-chevron-${value ? 'up' : 'down'}` }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>

                <v-list class="sign-identity__party-list" dense>
                  <v-list-item
                    v-for="proposerRole in availableProposerRole"
                    :key="proposerRole.id"
                    @click="setPartyAsRole(proposerRole)"
                  >
                    <v-list-item-title class="pl-1">
                      <span class="sign-identity__party-list--text">
                        {{ proposerRole.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>

            <v-col v-if="selectedWorkspaceTitle" class="col-auto">
              <span class="sign-identity__party-activator--text">{{ `${$t('general.workspace')}: ${this.selectedWorkspaceTitle}` }}</span>
            </v-col>

            <v-col v-if="isOneDevice">
              <v-checkbox
                v-model="signIdentityOneDevice"
                class="mt-0 pa-0 sign-identity__one-device--label"
                :label="$t('contract.one_device_sign')"
                :value="parseInt(1)"
                hide-details
                dense
              />
            </v-col>
          </v-row>
        </v-col>

        <v-expand-transition>
          <SignIdentityVerificationSettings
            v-show="blocks.verificationSettings"
            :sign-identity-email="hasVariablePosition"
          />
        </v-expand-transition>

        <v-expand-transition>
          <SignIdentitySettings v-show="blocks.signIdentitySettings" v-model="signIdentity" />
        </v-expand-transition>

        <v-expand-transition>
          <SignIdentityPersonalizedNotification
            v-show="blocks.notificationSettings"
            v-model="signIdentity"
          ></SignIdentityPersonalizedNotification>
        </v-expand-transition>
      </v-row>
    </v-form>
  </v-row>

  <v-skeleton-loader v-else class="skeleton-loader__sign-identity mb-4" type="article"></v-skeleton-loader>
</template>

<script>
import { defineComponent } from 'vue'
import { appLocales } from '@/app/config/locales'
import { useContractDocumentsStore } from '@/features/contracts/stores/contractDocuments'
import { useContractNoticesStore } from '@/features/contracts/stores/contractNotices'
import { useContractStore } from '@/features/contracts/stores/contract'
import { useContractSignTypesStore } from '@/features/contracts/stores/contractSignTypes'
import { useFirstStepValidationStore } from '@/features/contracts/stores/firstStepValidation'
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities'
import { useSignIdentityProposerRolesStore } from '@/features/contracts/stores/signIdentityProposerRoles'
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from '@/stores/workspace'
import { isEmptySignIdentity } from '@/features/contracts/services/signIdentitiesService'
import * as validation from '@/common/reusable/validation'
import FeatureFlagsChecker from '@/common/reusable/featureFlagsChecker'
import SignIdentitySearchInput from '@/features/contracts/components/SignIdentitySearchInput'
import SignIdentitySelect from '@/features/contracts/components/SignIdentitySelect.vue'
import SignIdentitySettings from '@/features/contracts/components/SignIdentitySettings.vue'
import SignIdentityPersonalizedNotification from '@/features/contracts/components/SignIdentityPersonalizedNotification.vue'
import SignIdentityVerificationSettings from '@/features/contracts/components/SignIdentityVerificationSettings.vue'
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions'
import { internalApiRequest } from '@/shared/services/apiService'

export default defineComponent({
  name: 'ContractSignIdentity',
  components: {
    SignIdentitySearchInput,
    SignIdentitySelect,
    SignIdentitySettings,
    SignIdentityPersonalizedNotification,
    SignIdentityVerificationSettings,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    signIdentityIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const contractDocumentsStore = useContractDocumentsStore()
    const contractNoticesStore = useContractNoticesStore()
    const contractStore = useContractStore()
    const contractSignTypesStore = useContractSignTypesStore()
    const firstStepValidationStore = useFirstStepValidationStore()
    const signIdentitiesStore = useSignIdentitiesStore()
    const signIdentityProposerRoles = useSignIdentityProposerRolesStore()
    const userStore = useUserStore()
    const workspaceStore = useWorkspaceStore()

    return {
      contractDocumentsStore,
      contractNoticesStore,
      contractStore,
      contractSignTypesStore,
      firstStepValidationStore,
      signIdentitiesStore,
      signIdentityProposerRoles,
      userStore,
      workspaceStore,
    }
  },
  data() {
    return {
      blocks: {
        verificationSettings: false,
        notificationSettings: false,
        signIdentitySettings: false,
      },
      signIdentityFormValid: true,
      partyColors: [
        '#8fcf4f',
        '#4D8AF4',
        '#FFC23F',
        '#AB47BC',
        '#E64A19',
        '#64FFDA',
        '#D4E157',
        '#FB8C00',
        '#EF535f',
        '#6D4C41',
        '#78909C',
      ],
      fullNameRules: [(v) => (!!v && validation.isFullNameValid(v)) || this.$t('login.name_invalid')],
      emailRules: [
        (v) => {
          const countOfOccurrences = this.signIdentitiesEmails.filter((s) => s === v.trim()).length

          if (countOfOccurrences > 1) {
            return this.$t('contract.errors.email_used')
          }

          return (!!v && validation.isEmailValid(v?.trim())) || this.$t('general.email_invalid')
        },
      ],
      contractRoleRules: [(v) => !!v],
      phoneNumberRules: [
        (v) => {
          const phoneNumberPreselection =
            appLocales?.find((appLocale) => appLocale.countryCode === this.userStore.userInvoiceInfo.invoice_country)
              ?.preferredPreselection || this.defaultPhonePrefix

          if (this.isPhoneNumberRequired) {
            return (
              (!!v &&
                (validation.isPhoneNumberValid(v) ||
                  validation.isPhoneNumberValid(`${phoneNumberPreselection}${v}`))) ||
              this.$t('contract.errors.mobile')
            )
          }

          if (
            !this.isPhoneNumberRequired &&
            (!v || appLocales?.map((appLocale) => appLocale.preferredPreselection)?.includes(v))
          ) {
            return true
          }

          return (
            validation.isPhoneNumberValid(v) ||
            validation.isPhoneNumberValid(`${phoneNumberPreselection}${v}`) ||
            this.$t('contract.errors.mobile')
          )
        },
      ],
      accountWorkspaces: [],
      selectedWorkspaceTitle: "",
    }
  },
  computed: {
    signIdentity: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', Object.assign({}, this.value, value))
      },
    },
    fullName: {
      get() {
        return `${this.signIdentity?.firstname || ''} ${this.signIdentity?.lastname || ''}`?.trim()
      },
      set(value) {
        const splitName = value.split(' ')

        this.signIdentity.firstname = splitName?.slice(0, 1).join()
        this.signIdentity.lastname = splitName?.slice(1)?.join(' ')
      },
    },
    email: {
      get() {
        return this.signIdentity?.email
      },
      set(value) {
        this.signIdentity.email = value?.trim()
      },
    },
    phoneNumber: {
      get() {
        return this.signIdentity?.mobile
      },
      set(value) {
        this.signIdentity.mobile = value
      },
    },
    contractRole: {
      get() {
        return this.signIdentity?.contract_role
      },
      set(value) {
        this.signIdentity.contract_role = value
      },
    },
    signIdentityOneDevice: {
      get() {
        return this.signIdentity?.one_device_group
      },
      set(value) {
        this.signIdentity.one_device_group = value
      },
    },
    isLoaded() {
      return this.contractSignTypesStore.availableTypes?.length >= 1
    },
    isLoadingDocuments() {
      return !this.contractDocumentsStore.isEveryDocumentLoaded
    },
    availableSignTypes() {
      return this.contractSignTypesStore.availableTypes
    },
    availablePartiesGroups() {
      const parties = []

      if (
        this.canAddProposerParty &&
        ('' === this.signIdentity?.email ||
          this.contractStore.availableProposers?.find((p) => this.signIdentity?.email === p.email))
      ) {
        parties.push('proposer')
      }

      return [...parties, ...this.signIdentitiesStore.signIdentitiesPartiesOrders]
    },
    availableProposerRole() {
      return this.signIdentityProposerRoles.availableProposersRoles
    },
    enableVariableUsers() {
      return FeatureFlagsChecker.enableVariableUsers(this.contractStore.data)
    },
    hasEnabledSigniAuthentication() {
      return FeatureFlagsChecker.hasEnabledSigniAuthentication(this.contractStore.data)
    },
    hasSequentialSigningOrder() {
      return this.contractStore.hasSequentialSigningOrder
    },
    canSelectProposerRole() {
      return this.signIdentity.is_proposer && !this.signIdentity.is_owner && this.canAddProposerRole
    },
    canAddNewSignIdentityParty() {
      return this.signIdentitiesStore.canAddNewSignIdentityParty
    },
    canAddProposerParty() {
      return this.signIdentitiesStore.canAddProposerParty
    },
    canAddProposerRole() {
      if ('legal' !== this.workspaceStore.workspaceType) {
        return false
      }

      return this.signIdentityProposerRoles.availableProposersRoles?.length && isEmptySignIdentity(this.signIdentity)
    },
    canUseSearchInput() {
      return !(this.signIdentity?.email || this.signIdentity?.variable_position)
    },
    defaultPhonePrefix() {
      return this.workspaceStore.workspaceSettings?.defaultPhonePrefix || '+420'
    },
    isPhoneNumberRequired() {
      return this.contractStore.featureFlags?.cantChangePhoneNumber
    },
    isDisabledChangeRole() {
      return this.contractStore.contract?.creator?.full_name === this.fullName || !!this.signIdentity?.variable_position
    },
    isProposer() {
      return this.signIdentity?.is_proposer
    },
    isProposerRole() {
      return !!this.signIdentity?.variable_position
    },
    partyColor() {
      if (this.signIdentity?.is_proposer || !this.signIdentity?.party_order) {
        return this.partyColors[0]
      }

      return this.partyColors[this.signIdentity?.party_order]
    },
    partyTitle() {
      return this.isProposer
        ? this.$t('contract.proposer')
        : `${this.$t('contract.counterparty')} ${this.signIdentity?.party_order}`
    },
    isOneDevice() {
      return (
        this.signIdentitiesStore?.signIdentities?.some((identity) => identity?.one_device_group) ||
        this.contractStore?.contract?.one_device ||
        false
      )
    },
    hasVariablePosition() {
      return this.signIdentity?.variable_position || this.signIdentity?.email
    },
    signIdentitiesEmails() {
      return this.signIdentitiesStore?.list.map((s) => s.email)
    },
    verificationDescription() {
      const [firstAuth] = this.signIdentity?.valid_auths ?? []
      const date = firstAuth?.valid_to

      return `${this.$t(
        `auth.group.default.${
          'undefined' === typeof this.signIdentity?.valid_auths?.bankId ? 'signi_verification' : 'bank_id'
        }`,
      )} - ${
        this.$t('general.valid_until').charAt(0).toLowerCase() + this.$t('general.valid_until').slice(1)
      } ${getFormattedDateByLocale({ date })}`
    },
    hasVerification() {
      return this.signIdentity?.valid_auths?.length > 0
    },
    isDisabledVerification() {
      return this.isProposer || this.isProposerRole || !this.hasEnabledSigniAuthentication || !this.email
    },
    canAddPersonalizedNotification() {
      return !this.signIdentity?.is_proposer && !this.email
    },
    assignWorkspaceToCounterPartyEnabled() {
      return FeatureFlagsChecker.assignWorkspaceToCounterPartyEnabled(this.workspaceStore.data)
    },
    isDisabledChoosingWorkspace() {
      return this.isProposer || this.isProposerRole || !this.email
    }
  },
  watch: {
    isLoaded: {
      handler: function (value) {
        if (value) {
          this.validateContractRole()
        }
      },
    },
    signIdentityFormValid: {
      handler: function (value) {
        this.$refs?.signIdentityForm?.validate()
        this.signIdentity.invalid = !value
      },
    },
    'signIdentity.contract_role': {
      handler: function () {
        if (this.isLoaded) {
          this.validateContractRole()
        }
      },
    },
    'signIdentity.is_proposer': {
      handler: function (value) {
        if (null === value) {
          this.signIdentity.is_proposer = true
        }
      },
    },
    'signIdentity.party_order': {
      handler: function (value) {
        if (null === value) {
          this.signIdentity.party_order = 1
        }
      },
    },
    'signIdentity.email': {
      handler: function () {
        if(validation.isEmailValid(this.signIdentity.email) && this.assignWorkspaceToCounterPartyEnabled) {
          this.fetchAccountWorkspaces()
        }
      },
    },
  },
  beforeMount() {
    if (!this.signIdentity.mobile) {
      if (this.userStore?.userInvoiceInfo?.invoice_country) {
        this.signIdentity.mobile =
          this.defaultPhonePrefix ||
          appLocales?.find((appLocale) => appLocale.countryCode === this.userStore.userInvoiceInfo.invoice_country)
            ?.preferredPreselection ||
          '+420'

        return
      }

      this.signIdentity.mobile =
        this.defaultPhonePrefix ||
        appLocales?.find((appLocale) => appLocale.locale === this.userStore.user?.locale)?.preferredPreselection
    }
  },
  mounted() {
    if (true === this.signIdentity?.signature_stamp) {
      this.signIdentity.contract_role = 'sign_type_a'
    }

    if(this.signIdentity.email && !this.signIdentity.is_proposer && this.assignWorkspaceToCounterPartyEnabled) {
      this.fetchAccountWorkspaces()
    }

    delete this.signIdentity?.workspace_id
  },
  methods: {
    saveWorkspaceToSignIdentity(workspace){
      this.signIdentity = Object.assign(
        this.signIdentity,
        { workspace_id: workspace.id }
      )
      this.selectedWorkspaceTitle = workspace.title
    },
    fetchAccountWorkspaces() {
      this.accountWorkspaces = []

      return internalApiRequest({
        method: 'GET',
        path: `api/v1/accounts/${this.workspaceStore.data?.account_id}/workspaces`,
      }).then((resp) => {
        Object.entries(resp).map(([workspaceId, workspaceTitle]) => {
          internalApiRequest({
            method: 'GET',
            path: `api/v1/workspaces/${workspaceId}/allowedusers`,
          }).then((resp) => {
            if(resp?.find((allowUsers) => allowUsers.email === this.signIdentity.email)) {
              this.accountWorkspaces.push({
                id: parseInt(workspaceId),
                title: workspaceTitle,
              })
            }
          }).catch((err) => {
            this.accountWorkspaces = []
            this.$notification.error(err.response?.data || err)
          })
        })
      })
    },
    validateContractRole() {
      const isContractRoleValid = this.availableSignTypes
        ?.map((t) => t?.value)
        ?.includes(this.signIdentity?.contract_role)

      if (!isContractRoleValid) {
        this.signIdentity.contract_role = this.availableSignTypes[0]?.value
      }
    },
    toggleBlock(currentBlock) {
      Object.keys(this.blocks)?.forEach((blockKey) => {
        if (currentBlock === blockKey) {
          this.blocks[`${blockKey}`] = !this.blocks[`${blockKey}`]
        } else {
          this.blocks[`${blockKey}`] = false
        }
      })
    },
    updateSignIdentity(data) {
      delete data.workspace_id

      this.signIdentity = Object.assign(
        this.signIdentity,
        {
          is_proposer: false,
          party_order: this.signIdentitiesStore.lastSignIdentityPartyOrder,
        },
        data,
        {
          personalNewNotification: '',
        }
      )
    },
    setPartyAsRole(role) {
      this.signIdentitiesStore.list[this.signIdentityIndex] = Object.assign(
        this.signIdentitiesStore.list[this.signIdentityIndex],
        {
          variable_position: role.name,
          is_proposer: true,
        },
      )
    },
    setNewPartyOrder() {
      this.setSignIdentityPartyOrder(this.signIdentitiesStore.lastSignIdentityPartyOrder + 1)
    },
    setSignIdentityPartyOrder(partyOrder) {
      this.signIdentity.is_proposer = 'proposer' === partyOrder
      this.signIdentity.party_order = 'proposer' === partyOrder ? 1 : partyOrder
    },
    deleteCurrentSignIdentity() {
      this.signIdentitiesStore.removeSignIdentity(this.signIdentityIndex)

      this.contractNoticesStore.updateNotices(
        this.contractNoticesStore?.notices?.filter((notice) => !notice.recipients.includes(this.signIdentity?.email)),
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.sign-identity {
  &__wrapper {
    min-height: 155px;
    border: 1px solid #d4dff0;
    border-radius: 5px;
    overflow: hidden;
  }

  &__order-number {
    color: #868e96;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }

  &__settings-label {
    text-transform: uppercase;
    color: #868e96;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;

    &--disabled {
      opacity: 0.5;
    }
  }

  &__settings-input {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;

    &--disabled {
      opacity: 0.5;
      color: #868e96;
    }
  }

  &__party-activator {
    width: fit-content;

    &--text {
      color: #868e96;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
    }
  }

  &__party-list {
    border: 1px solid #7031b4;
    border-radius: 4px;
    background-color: #ffffff;

    &--text {
      color: #424242;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17.5px;
    }
  }

  &__one-device--label::v-deep {
    .v-label {
      color: #424242;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17.5px;
    }
  }
}
</style>
